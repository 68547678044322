<template>
  <div class="content-page" v-if="contentPageLoaded">
    <div class="content-element-list">
      <div class="content-element" 
        v-for="(contentElement, index) in currentContentPage.contentElements"
        v-bind:key="index"
      >
        <template v-if="contentElement && contentElement.type">
          <div class="image-wrapper" v-if="'image' === contentElement.type">
            <ImageViewer :srcSet="contentElement.versions" />
            <p v-if="contentElement.caption" class="caption">
              {{ contentElement.caption }}
            </p>
          </div>
          <div v-if="'text' === contentElement.type" class="text" v-html="markdownToHtml(contentElement.text)">
          </div>
          <div class="video-wrapper" v-if="'video' === contentElement.type">
            <div v-if="false === consentAccepted" class="warning-consent">
              <span>
                Sie müssen externe Inhalte (Youtube) zulassen.
              </span>
              <custom-button @click="showCookieConsent">show consent</custom-button>
            </div>
            <template v-else>
              <YouTube  class="video" :src="appendExternalUrl(contentElement.url)" ref="youtube" width="100%" />
              <p class="caption">{{ contentElement.caption }}</p>
            </template>
          </div>
          <div class="pdf" v-if="'pdf' === contentElement.type">
            <download-button :url="contentElement.url" :fileName="contentElement.description" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { marked } from 'marked';
import YouTube from 'vue3-youtube';

import DownloadButton from './DownloadButton.vue';

import { appendExternalUrl } from '@/helpers/fileHandler';
import ImageViewer from './ImageViewer.vue';
import CustomButton from './CustomButton.vue';

export default {
  name: 'ContentPage',
  components: {
    DownloadButton,
    YouTube,
    ImageViewer,
    CustomButton,
  },
  props: {
    contentPageId: {
      type: String,
      required: true,
    }
  },
  mounted() {
    // loads content for specific page
    this.$store.dispatch('loadContentPage', this.contentPageId);
  },
  computed: {
    ...mapState([
      'currentContentPage',
      'contentPageLoaded',
      'consentAccepted',
    ]),
  },
  methods: {
    showCookieConsent() {
      this.$store.dispatch('setConsentVisibility', true);
    },
    appendExternalUrl,
    markdownToHtml(markdown) {
      let parsedHtml = marked.parse(JSON.parse(JSON.stringify(markdown)));
      parsedHtml = this.resolveInternalLinks(parsedHtml);
      return parsedHtml;
    },
    resolveInternalLinks(text) {
      let htmlText = text;
      // eslint-disable-next-line
      const regex = '[href=\"][0-9]:[0-9]+\"';
      const matches = [...htmlText.matchAll(regex)];
      for (const match of matches) {

        let linkToContentPageId = match[0].replaceAll('"', '');
        const [categoryId, contentId] = linkToContentPageId.split(':');
        htmlText = htmlText.replace(`${match[0]}`, `"/${categoryId}/${contentId}"`)
      }
      return htmlText;
    },
    formatDate(dateString) {
      const dateTime = new Date(dateString);
      return dateTime.toLocaleDateString('de-DE');
    },
  },
}

</script>

<style lang="scss">
.content-page {
  color: white;

  .info {
    font-size: 10px;
  }

  .smooth-reflow-wrapper {
    box-shadow: 0 -20px 20px -20px red inset;
  } 

  .category-list {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  .content-element-list {
    width: inherit;
    padding-bottom: 30px;
    
    .content-element {
      width: inherit;
      margin: 10px 0 20px 0;

      &:last-of-type {
        padding-bottom: 35px;
      }

      .text {
        p {
          margin-bottom: 5px;
        }
        h2 {
          margin: 10px 0 5px 0;
        }

        h3 {
          margin: 7px 0 5px 0;
        }

        a {
          color: #EAFD74;
          text-decoration: none;
        }

        li {
          margin-left: 20px;
        }
      }

      .image-wrapper {
        .caption {
          font-size: 12px;
          font-style: italic;
        }
      }

      .video-wrapper {
        .warning-consent {
          border: 1px solid white;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        .video {
          width: 100%;
        }

        .caption {
          font-size: 12px;
          font-style: italic;
        }
      }
    }

    &:last-of-type {
      .image-wrapper, .video-wrapper {
        .caption {
          padding: 5px 0 20px 0;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .content-element {
      padding: 10px 0;
    }
  }
}
</style>