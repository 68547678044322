<template>
    <div class="search-bar" id="search-bar">
        <div 
            class="placeholder"
            :class="searchActive ? 'active' : 'inactive'" 
        ></div>
        <form class="search form">
            <input 
                v-model="searchText" 
                class="search search-field" 
                :class="searchActive ? 'active' : 'inactive'" 
                @input="updateSearchText" 
                @click="handleClick"
                type="search" 
                placeholder="Search..."
            >
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchText: '',
            searchActive: false,
        }
    },
    methods: {
        handleClick() {
            if (!this.searchActive) {
                this.searchActive = true;
            } else {
                this.searchText = ''; 
                this.updateSearchText();
            }
        },
        updateSearchText() {
            if (1 < this.searchText.length) {
                this.$store.dispatch('loadContentPagesViaSearch', this.searchText);
            } else if ('' === this.searchText) {
                this.$store.dispatch('loadContentPagesViaSearch');
            }
        },
        setSearchStatus(status) {
            this.searchActive = status;
        }
    },
    mounted() {
        const body = document.getElementsByTagName('body')[0];
        body.addEventListener('click', (event) => {
            isClickOnSearchBar(event);
        });
        body.addEventListener('touchmove', (event) => {
            isClickOnSearchBar(event);
        })

        const isClickOnSearchBar = (event) => {
            const searchBar = document.getElementById('search-bar');
            if (!searchBar) return;
            if (!searchBar.contains(event.target)) {
                this.setSearchStatus(false);
            }
        }
    },
}
</script>

<style lang="scss">
.search-bar {
    display: flex;
    padding-right: 10px;
    form {
        color: #555;
        display: flex;
        padding: 2px;
        border: 1px solid currentColor;
        border-radius: 5px;
    }

    input[type="search"] {
        border: none;
        background: transparent;
        margin: 0;
        padding: 7px 8px;
        font-size: 14px;
        color: inherit;
        border: 1px solid transparent;
        border-radius: inherit;

        transition: .5s width ease;      
        
        &.active {
            width: 150px;

        }

        &.inactive {
            width: 40px;
        }
    }

    input[type="search"]:focus {
        box-shadow: 0 0 3px 0 black;
        border-color: black;
        outline: none;
    }

    form.search {
        border: none;
        padding: 0;
    }

    input.search {
        border: 1px solid #555;
        display: block;
        padding: 9px 4px 9px 40px;
        color: black;
        background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
    }
    .placeholder {
        transition: .5s width ease;      
    }
}
</style>