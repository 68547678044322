import { createWebHistory, createRouter } from "vue-router";
import OverviewPage from '@/components/OverviewPage.vue';
// import ContentPage from '@/components/ContentPage.vue';
import { store } from "@/store";

const routes = [
  {
    path: '/',
    component: OverviewPage,
    meta: {
      hasSearchBar: true,
    }
  },
  {
    path: '/:categoryId',
    component: OverviewPage,
    meta: {
      hasSearchBar: false,
    }
  },
  {
    path: '/:categoryId/:id',
    component: OverviewPage,
    meta: {
      hasSearchBar: false,
    }
  }
];

const router = new createRouter({
  history: createWebHistory(),
  routes,
});

// Remove searchbar for Content Page
router.beforeEach((to) => {
  if (to.meta.hasSearchBar) {
    store.dispatch('setSearchBarVisibility', true);
  } else {
    // store.dispatch('setSearchBarVisibility', false);
    store.dispatch('setSearchBarVisibility', true);
  }
});

export default router;
