import { 
  fetchContentPageDetail,
  fetchContentPagesOverview,
  fetchContentCategories,
  fetchContentPagesOverviewWithSearch
} from "@/api/requests";

import { createStore } from 'vuex'

// Create a new store instance.
export const store = createStore({
  state () {
    return {
      contentPages: [],
      contentPagesRelationModel: null,
      currentContentPage: null,
      contentCategories: [],
      // page ready
      contentPagesLoaded: false,
      contentPageLoaded: false,
      searchBarVisibility: true,
      consentVisibility: false,
      consentAccepted: false,
    }
  },
  // access data
  getters: {
    getContentPages(state) {
      return state.contentPages;
    },
    // get single content page for provided id
    getCurrentContentPage: (state) => {
      return state.currentContentPage; 
    },
    getContentCategories: (state) => {
      return state.contentCategories; 
    },
    isSearchBarVisible(state) {
      return state.searchBarVisibility;
    },
    getContentPageForId: (state) => (contentPageId) => {
      return state.contentPages.map(contentPage => {
        return contentPage.id.toString() === contentPageId.toString()
      });
    },
  },
  // modify state
  mutations: {
    SET_CONTENT_PAGES_LOAD_STATUS(state, status) {
      state.contentPagesLoaded = status;
    },
    SET_CONTENT_PAGE_LOAD_STATUS(state, status) {
      state.contentPageLoaded = status;
    },
    LOAD_CONTENT_PAGES(state, contentPages) {
      state.contentPages = contentPages;
    },
    LOAD_CONTENT_PAGE(state, contentPage) {
      state.currentContentPage = contentPage;
    },
    LOAD_CONTENT_CATEGORIES(state, contentCategories) {
      state.contentCategories = contentCategories;
    },
    SET_SEARCHBAR_VISIBILITY(state, status) {
      state.searchBarVisibility = status;
    },
    SET_CONSENT_VISIBILITY(state, status) {
      state.consentVisibility = status;
    },
    SET_CONSENT_ACCEPTED(state, status) {
      state.consentAccepted = status;
    }
  },
  // trigger mutations
  actions: {
    async loadContentPages(context) {
      context.commit('SET_CONTENT_PAGES_LOAD_STATUS', false);

      const contentPages = await fetchContentPagesOverview();
      context.commit('LOAD_CONTENT_PAGES', contentPages);

      context.commit('SET_CONTENT_PAGES_LOAD_STATUS', true);
    },
    // triggered via the search component
    async loadContentPagesViaSearch(context, searchText) {
      context.commit('SET_CONTENT_PAGES_LOAD_STATUS', false);

      // const contentPages = await fetchContentPagesOverview(searchText);
      const contentPages = await fetchContentPagesOverviewWithSearch(searchText);
      context.commit('LOAD_CONTENT_PAGES', contentPages);

      context.commit('SET_CONTENT_PAGES_LOAD_STATUS', true);
    },
    // requests a single content page, also used for internal links in richtext
    async loadContentPage(context, contentPageId) {
      context.commit('SET_CONTENT_PAGE_LOAD_STATUS', false);

      const contentPage = await fetchContentPageDetail(contentPageId);
      context.commit('LOAD_CONTENT_PAGE', contentPage);

      context.commit('SET_CONTENT_PAGE_LOAD_STATUS', true);
    },

    // requests a single content page
    async loadContentPageViaCategories(context) {
      context.commit('SET_CONTENT_PAGES_LOAD_STATUS', false);

      const contentPages = await fetchContentPagesOverview(null, context.state.categoriesSelected);
      context.commit('LOAD_CONTENT_PAGES', contentPages);

      context.commit('SET_CONTENT_PAGES_LOAD_STATUS', true);
    },

    // requests collection of categories
    async loadContentCategories(context) {
      context.commit('SET_CONTENT_PAGES_LOAD_STATUS', false);

      const contentCategories = await fetchContentCategories();
      context.commit('LOAD_CONTENT_CATEGORIES', contentCategories);

      context.commit('SET_CONTENT_PAGES_LOAD_STATUS', true);
    },
    setSearchBarVisibility(context, status) {
      context.commit('SET_SEARCHBAR_VISIBILITY', status);
    },
    setConsentVisibility(context, status) {
      context.commit('SET_CONSENT_VISIBILITY', status);
    },
    setConsentAccepted(context, status) {
      context.commit('SET_CONSENT_ACCEPTED', status);
    }
  }
})