import Cookies from "js-cookie";

export const CONSENT_TYPES = {
    YOUTUBE: 'consent-youtube',
};

export const CookieHandler = {
    set(key, value) {
        if (key) Cookies.set(key, JSON.stringify(value));
    },
    get(key) {
        const consent = Cookies.get(key);
        if (consent) return JSON.parse(consent);
    }
}