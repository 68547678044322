<template>
  <div class="image-container">
    <img :src="resolveUrl(srcSet, IMG_SIZES.SMALL)" @click="showImage">
    <div v-if="isShowing" class="overlay" @click="hideImage">
      <img :src="resolveUrl(srcSet, IMG_SIZES.STANDARD)" class="maximized">
    </div>
  </div>
</template>

<script>
import { appendExternalUrl } from '@/helpers/fileHandler';
import { IMG_SIZES } from '@/types/index';

export default {
  props: {
    srcSet: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShowing: false,
      IMG_SIZES: IMG_SIZES,
    }
  },
  methods: {
    showImage() {
      this.isShowing = true
      document.body.style.overflow = 'hidden'
    },
    hideImage() {
      this.isShowing = false
      document.body.style.overflow = 'auto'
    },
    resolveUrl(image, preferredImageSize) {
      if (!image) return null;
      if (image[preferredImageSize]) return appendExternalUrl(image[preferredImageSize])
      return appendExternalUrl(image[IMG_SIZES.STANDARD]);
    },
  },
}
</script>

<style lang="scss">
.image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;

  img {
    max-height: 90vh;
    max-width: 95%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.maximized {
  max-width: 90%;
  max-height: 80%;
}

@media screen and (min-width: 1024px) {
    .image-container {
      max-width: 75%;
      max-height: 80vh;
    }
  }
</style>
