import axios from "axios";
import { 
  createContentPageObject,
  createContentCategoryObject
} from "@/types/index.js";

import {
  endpoints,
  filters,
  queries,
} from './types.js'

const baseUri = process.env.VUE_APP_STRAPI_URL_BE;
let queryIndex = 0;

export const fetchContentPagesOverviewWithSearch = async (searchText) => {
  // fetch all contentPages
  const content = await fetchContentPagesOverview();

  if (!searchText || 2 > searchText.length) {
    return content;
  }

  // filter contentPages
  return content.filter(contentPage => {
    const title = contentPage.title.toLowerCase();
    const texts = contentPage.contentElements.filter(contentElement => {
      if ('text' === contentElement.type) {
        return contentElement.text;
      }
    });
    // check for occurence of searchText in elements
    if (title.toLowerCase().includes(searchText.toLowerCase())) return contentPage;
    for (const text of texts) {
      if (text.text.toLowerCase().includes(searchText.toLowerCase())) return contentPage;
    }
  })
}

export const fetchContentPagesOverview = async (searchText = null, selectedCategories = null) => {
  // reset query index
  queryIndex = 0;

  const contentPagesResponse = await axios.get(
    buildRequestUriWithQueries(
      endpoints.contentPage,
      null,
      [
        setQueryIndex(queries.contentPage.id),
        setQueryIndex(queries.contentPage.title),
        setQueryIndex(queries.contentPage.created),
        setQueryIndex(queries.contentPage.published),
        setQueryIndex(queries.contentPage.contentCategory),
        setQueryIndex(queries.contentPage.content),
        setQueryIndex(queries.contentPage.images),
        setQueryIndex(queries.contentPage.rank),
        setQueryIndex(queries.contentPage.videos),
        setQueryIndex(queries.contentPage.pdf),
      ],
      searchText ? searchText : null,
      selectedCategories ? selectedCategories : null,
    )
  );

  if (200 !== contentPagesResponse.status || !contentPagesResponse.data || !contentPagesResponse.data.data) {
    console.error('Error while fetching content', contentPagesResponse);
    return;
  }

  // transform to usable objects
  let contentPageList = [];
  for (let i = 0; i < contentPagesResponse.data.data.length; i++) {
    const contentPageObject = createContentPageObject(contentPagesResponse.data.data[i]);
    contentPageList.push(contentPageObject);
  }

  return contentPageList;
};

export const fetchContentPageDetail = async (contentPageId) => {  
  // reset query index
  queryIndex = 0;
  
  const contentPageData = await axios.get(
    buildRequestUriWithQueries(
      endpoints.contentPage,
      contentPageId,
      [
        setQueryIndex(queries.contentPage.id),
        setQueryIndex(queries.contentPage.title),
        setQueryIndex(queries.contentPage.created),
        setQueryIndex(queries.contentPage.published),
        setQueryIndex(queries.contentPage.contentCategory),
        setQueryIndex(queries.contentPage.content),
        setQueryIndex(queries.contentPage.rank),
        setQueryIndex(queries.contentPage.images),
        setQueryIndex(queries.contentPage.videos),
        setQueryIndex(queries.contentPage.pdf),
      ],
      null,
      null,
    )
  );

  if (200 !== contentPageData.status || !contentPageData.data || !contentPageData.data.data) {
    console.error('Error while fetching content', contentPageData);
    return;
  }

  const contentPageObject = createContentPageObject(contentPageData.data.data);
  return contentPageObject;
};

export const fetchContentCategories = async () => {
  // reset query index
  queryIndex = 0;
  
  const contentCategoriesReponse = await axios.get(
  `${baseUri}/${endpoints.contentCategories}`
  );

  // transform to usable objects
  if (
    200 !== contentCategoriesReponse.status ||
    !contentCategoriesReponse || 
    !contentCategoriesReponse.data || 
    !contentCategoriesReponse.data.data
  ) {
    return;
  }

  const contentCategoryObjects = contentCategoriesReponse.data.data.map(contentCategory => {
    return createContentCategoryObject(contentCategory);
  });

  return contentCategoryObjects;
};

// functionality

const buildRequestUriWithQueries = (endpoint, params = null, queries = null, searchText = null, selectedCategories = null) => {
  let uriComplete = `${baseUri}/${endpoint}`;

  if (params) {
    uriComplete = uriComplete.concat(`/${params}`)
  } 
  if (queries) {
    // append query params
    uriComplete = `${uriComplete}?${queries.join('&')}`;
  }
  if (searchText) {
    // filter in titles and text
    const searchOptions = [
      filters.attributes.content,
      filters.attributes.title
    ];

    for (const searchOption of searchOptions) {
      uriComplete = uriComplete.concat(
        setQueryIndex(
          `&filters${searchOption}${filters.operators.contains}[#queryIndex]=${searchText}`
        )
      );
    }
  }
  if (selectedCategories) {
    const selectedCategoriesUrlExtension = selectedCategories.map(selectedCategory => {
      //&filters[content_categories][id][$eq]=1
      return setQueryIndex(`&filters${filters.attributes.contentCategory}[id]${filters.operators.equals}=${selectedCategory.id}`);
    });
    uriComplete = uriComplete.concat(selectedCategoriesUrlExtension);
  }

  // append sorted for rank
  uriComplete = uriComplete.concat('&sort=rank:asc');
  // reset default result limit
  uriComplete = uriComplete.concat('&pagination[limit]=1000');

  return uriComplete;
}

function setQueryIndex(query) {
  ++queryIndex;
  return query.replace('#queryIndex', queryIndex.toString());
}