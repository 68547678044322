<template>
  <div class="navigation-bar">
    <router-link 
      class="title"
      to="/"
    >
      <h1>Fuldwerk-Wiki</h1>
    </router-link>
    <search-bar v-if="searchBarVisibility" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchBar from './SearchBar.vue';

export default {
  name: 'NavigationBar',
  components: {
    SearchBar,
  },
  computed: {
    ...mapState({
      searchBarVisibility: 'searchBarVisibility'
    })
  }
}

</script>

<style lang="scss">
.navigation-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;

  z-index: 1;
  background-color: black;
  border-bottom: 1px solid white;
  position: fixed;

  .title {
    padding-left: 20px;
    text-decoration: none;
    font-size: 10px;

    &>* {
      width: max-content;
    }
  }
}
</style>