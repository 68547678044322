export const queries = {
    contentPage: {
        id: 'fields[#queryIndex]=id',
        title: 'fields[#queryIndex]=title',
        created: 'fields[#queryIndex]=createdAt',
        published: 'fields[#queryIndex]=publishedAt',
        rank: 'fields[#queryIndex]=rank',
        content: 'populate[#queryIndex]=content.textBox',
        images: 'populate[#queryIndex]=content.image',
        videos: 'populate[#queryIndex]=content.video',
        pdf: 'populate[#queryIndex]=content.pdf',
        // populate field categories, pdf download
        contentCategory: 'populate[#queryIndex]=content_category',
    }
}

export const endpoints = {
    contentPage: 'content-pages',
    contentCategories: 'content-categories',
  }
  
export const filters = {
    operators: {
        equals: '[$eq]',
        inArray: '[$in]',
        contains: '[$contains]',
    },
    attributes: {
        title: '[title]',
        content: '[content]',
        contentCategory: '[content-category]',
    }
}

export const contentType = {
    text: 'textBox',
    image: 'image',
    video: 'video',
    pdf: 'pdf',
}