<template>
    <component class="smooth-reflow-wrapper"
      :is="tag"
      :style="[
        inlineStyle,
      ]"
      :class="{
        '--vertical-mode': !horizontalMode,
        '--horizontal-mode': horizontalMode,
        '--overflow-hidden': (hideOverflow || animating),
      }"
    >
      <div ref="container" class="smooth-reflow-container">
        <slot></slot>
      </div>
    </component>
  </template>
  
  <script>
  export default {
    name: 'SmoothReflow',
  
    props: {
      tag: {
        type: String,
        default: 'div',
      },
  
      horizontalMode: {
        type: Boolean,
        default: false,
      },
  
      transitionDuration: {
        type: Number,
        default: 370,
      },
  
      transitionDelay: {
        type: Number,
        default: 0,
      },
  
      transitionEasing: {
        type: String,
        default: 'ease-in-out',
      },
  
      hideOverflow: {
        type: Boolean,
        default: true,
      },
    },
  
    data() {
      return {
        observer: null,
        animating: false,
        containerEl: null,
        containerWidth: 0,
        containerHeight: 0,
        height: null,
        width: null,
      };
    },
  
    computed: {
      inlineStyle() {
        if (this.width === null) {
          return '';
        }
        if (this.horizontalMode == true) {
          return {
            width: `${this.width}px`,
            transition: this.getTransition,
          };
        }
        return {
          height: `${this.height}px`,
          transition: this.getTransition,
        };
      },
  
      getTransition() {
        if (this.horizontalMode === true) {
          return `width ${this.transitionDuration}ms ${this.transitionEasing} ${this.transitionDelay}ms`;
        }
  
        return `height ${this.transitionDuration}ms ${this.transitionEasing} ${this.transitionDelay}ms`;
      },
    },
  
    methods: {
      initObserver() {
        this.observer = new ResizeObserver((entries) => {
          for (const entry of entries) {
            if (entry.contentBoxSize) {
              // Firefox implements `contentBoxSize` as a single content rect, rather than an array
              const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
  
              this.width = contentBoxSize.inlineSize;
              this.height = contentBoxSize.blockSize;
            } else {
              this.width = entry.contentRect.width;
              this.height = entry.contentRect.height;
            }
          }
        });
      },
  
      startObserver() {
        this.observer.observe(this.$refs.container);
      },
  
      onAnimationStart(e) {
        if (e.target === this.$el) {
          this.animating = true;
        }
      },
  
      onAnimationEnd(e) {
        if (e.target === this.$el) {
          this.animating = false;
        }
      },
    },
  
    mounted() {
      this.$el.addEventListener('transitionstart', this.onAnimationStart);
      this.$el.addEventListener('transitionend', this.onAnimationEnd);
  
      this.initObserver();
      this.startObserver();
    },
  
    beforeUnmount() {
      this.$el.removeEventListener('animationstart', this.onAnimationStart);
      this.$el.removeEventListener('animationend', this.onAnimationEnd);
    },
  };
  </script>
  
  <style lang="scss">
  .smooth-reflow-wrapper {
    position: relative;
    flex-shrink: 0;
    // transition: height 370ms ease-in-out;
  
    &.--overflow-hidden {
      overflow: hidden;
    }
  
    .smooth-reflow-container {
      position: relative;
      top: 0;
      // width: fit-content;
      display: block;
    }
  
    &.--horizontal-mode {
      .smooth-reflow-container {
        display: inline-block;
        width: fit-content;
      }
    }
  }
  
  .smooth-reflow {
    &-enter-active,
    &-leave-active {
      // transition: height 370ms ease-in-out;
      overflow: hidden;
    }
  
    &-enter-from,
    &-leave-to {
      height: 0;
    }
  }
  // reusable transition to keep content in smooth reflow visisble
  .smoothReflowPersist {
    &-leave-active {
      position: absolute;
      width: 100%;
      overflow: visible;
      // default reflow duration 500ms
      transition: opacity 500ms linear;
    }
    // &-enter-active {}
  
    // &-enter {}
    &-leave-to {
      opacity: 0;
    }
  }
  </style>
  