<template>
    <div v-if="consentVisibility" class="cookie-banner">
      <p>We use cookies to enhance your user experience. By continuing to browse this site, you consent to our use of cookies.</p>
      <div class="button-group">
        <custom-button @click="acceptCookie">Accept</custom-button>
        <custom-button @click="declineCookie">Decline</custom-button>
      </div>
    </div>
  </template>
  
  <script>  
  import CustomButton from '@/components/CustomButton.vue';
  import { mapState } from 'vuex';
  import { CookieHandler, CONSENT_TYPES } from '@/helpers/cookieHandler';
  export default {
    name: "CookieConsent",
    components: {
      CustomButton,
    },
    // data() {
    //   return {
    //     show: false,
    //   }
    // },
    computed: {
      ...mapState([
        'consentVisibility'
      ]),
    },
    mounted() {
      const youtubeConsent = CookieHandler.get(CONSENT_TYPES.YOUTUBE);
      if (undefined === youtubeConsent) {
        this.$store.dispatch('setConsentVisibility', true);
      } else if (youtubeConsent && true === youtubeConsent['interacted']){
        this.$store.dispatch('setConsentVisibility', false);
      }
    },
    methods: {
      acceptCookie() {
        CookieHandler.set(CONSENT_TYPES.YOUTUBE, {
          interacted: true,
          accepted: true,
        });
        this.$store.dispatch('setConsentVisibility', false);
        this.$store.dispatch('setConsentAccepted', true);
      },
      declineCookie() {
        CookieHandler.set(CONSENT_TYPES.YOUTUBE, {
          interacted: true,
          accepted: false,
        });
        this.$store.dispatch('setConsentVisibility', false);
        this.$store.dispatch('setConsentAccepted', false);
      }
    }
  };
  </script>
  
  <style lang="scss">
  .cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 10px 100px;
    margin: 20px;

    background-color: black;

    border: 2px solid rgb(220, 220, 220);

    color: rgb(220, 220, 220);
    text-align: center;
  }

  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  } 
  

  @media (max-width: 450px) {
    .cookie-banner {
      padding: 10px;
    }
  }  

  @media screen and (min-width: 1024px) {
    .cookie-banner {
      margin: 0 200px;
    }
  }

  </style>
  