<template>
  <div class="content-page-list" v-if="contentPagesLoaded">
    <div 
      class="category-container"
      v-for="category in contentSortedForCategories" 
      v-bind:key="category.id"
    >
      
      <h2
        v-if="0 < category.contentPages.length"
        class="category-title"
        :class="isOpenCategory(category.id) ? 'active' : ''"
        @click="toggleCollapse(category.id, null)"
      >
        {{ category.title }}
      </h2>

      <smooth-reflow transition-easing="ease">
        <template
          v-for="contentPage in category.contentPages"
        >
          <div 
            v-if="isOpenCategory(category.id)"
            v-bind:key="contentPage.id" 
            class="content-detail"
          >
            <div 
              class="header"
              @click="toggleCollapse(null, contentPage.id)"
            >
              <h3 
                class="title"
                :class="isOpenContent(contentPage.id) ? 'active' : ''"
              >
                {{ contentPage.title }}
              </h3>
            </div>
            <smooth-reflow transition-easing="ease">
              <div 
                class="content-area"
                v-if="isOpenContent(contentPage.id)"
              >
                <content-page :contentPageId="contentPage.id" />
              </div>
            </smooth-reflow>
          </div>
        </template>
      </smooth-reflow>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentPage from './ContentPage.vue';
import { createDisplayableCategoryToContentPageObjects } from '../types/index.js';
import SmoothReflow from './SmoothReflow.vue';

export default {
  name: 'OverviewPage',
  data() {
    return {
      contentSortedForCategories: null,
      currentPageOpen: null,
      currentCategoryOpen: null,
    }
  },
  components: {
    ContentPage,
    SmoothReflow,
  },
  beforeCreate() {
      this.$store.dispatch('loadContentPages');
      this.$store.dispatch('loadContentCategories');
  },
  mounted() {
    let currentPath = window.location.pathname;

    const pageParams = currentPath.split('/');

    let categoryId = null;
    let contentPageId = null;

    if (pageParams.length === 3) {
      categoryId = pageParams[pageParams.length-2];
      contentPageId = pageParams[pageParams.length-1];
    } else if (pageParams.length === 2) {
      if ("" !== pageParams[pageParams.length-1]) {
        categoryId = pageParams[pageParams.length-1];
      }
    }

    this.toggleCollapse(categoryId, contentPageId);
  },
  computed: {
    ...mapState({
      contentPages: 'contentPages',
      contentPagesLoaded: 'contentPagesLoaded',
      contentCategories: 'contentCategories',
    }),
  },
  methods: {
    createCategoryContentPageObject() {
      if (0 < this.contentPages.length && 0 < this.contentCategories.length) {
        const displayableCategoryWithContent = createDisplayableCategoryToContentPageObjects(this.contentPages, this.contentCategories);
        // sort categories by rank
        this.contentSortedForCategories = displayableCategoryWithContent.sort(this.sortCategoriesByRank);
      } else {
        this.contentSortedForCategories = null;
      }
    },
    toggleCollapse(categoryId = null, contentPageId = null) {
      const currentUnixTS = Math.floor(Date.now() / 1000);
      if (categoryId && contentPageId) {
        this.$router.push({ path: `/${categoryId}/${contentPageId}`, query: { stamp: currentUnixTS} });
        this.currentCategoryOpen = categoryId;
        this.currentPageOpen = contentPageId;
      }

      if (categoryId && !contentPageId) {
        if (categoryId === this.currentCategoryOpen) {
          this.$router.push({ path: `/`, query: { stamp: currentUnixTS} });
          this.currentCategoryOpen = null;
        } else {
          this.$router.push({ path: `/${categoryId}`, query: { stamp: currentUnixTS} });
          this.currentCategoryOpen = categoryId;
        }
      }

      if (!categoryId && contentPageId && this.currentCategoryOpen) {
        if (contentPageId === this.currentPageOpen) {
          this.$router.push({ path: `/${this.currentCategoryOpen}`, query: { stamp: currentUnixTS} });
          this.currentPageOpen = null;
        } else {
          this.$router.push({ path: `/${this.currentCategoryOpen}/${contentPageId}`, query: { stamp: currentUnixTS} });
          this.currentPageOpen = contentPageId;
        }
      }
    },
    isOpenContent(contentPageId) {
      return this.currentPageOpen === contentPageId;
    },
    isOpenCategory(categoryId) {
      return this.currentCategoryOpen === categoryId;
    },
    sortCategoriesByRank(categoryA, categoryB) {
      if (categoryA.rank && categoryB.rank) {
        if (categoryA.rank < categoryB.rank) {
          return -1;
        }
        if (categoryA.rank > categoryB.rank) {
          return 1;
        }
      }
      if (categoryA.id > categoryB.id) {
        return -1;
      }
      if (categoryA.id > categoryB.id) {
        return 1;
      }
    },
  },
  watch: {
    contentCategories() {
      this.createCategoryContentPageObject();
    },
    contentPages() {
      this.createCategoryContentPageObject();
    }
  }
}

</script>

<style lang="scss">
.content-page-list {
  padding: 50px 20px;
  .category-title {
    margin-top: 15px;
    scroll-margin-top: 60px;
    cursor: pointer;
    
    &.active {
      margin: 15px 0;
    }
  }
  .content-detail {
    margin: 0 10px;

    .header {
      display: flex;
      justify-content: space-between;

      cursor: pointer;

      .title {
        font-weight: normal;
        transition: .3s font-size ease;
        max-width: 90%;
      }
    }
  }

  .category-title, .title {
    &.active {
      color: #EAFD74;
    }
  }
}

@media screen and (min-width: 1024px) {
  .content-page-list {
    padding: 50px 200px;
  }
}

@media (max-width: 450px) {
  .toggle-item {
    display: none;
  }
} 
</style>