<template>
    <a 
        :class="`download-button`"
        :href="appendExternalUrl(this.url)"
        :download="fileName"
        target="_blank"
    >
        {{ fileName ? fileName : 'Download' }} 
    </a>
</template>

<script>
import { appendExternalUrl } from '@/helpers/fileHandler';
export default {
    name: 'DownloadButton',
    props: {
        url: {
            type: String,
            required: true,
        },
        fileName: {
            type: String,
            default: 'Download',
        },
    },
    methods: {
        appendExternalUrl,
    }
}
</script>

<style lang="scss">
.download-button {
    height: 30px;
    border-radius: 3px;
    padding: 7px;
    background-color: white;
    color: black;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid black;

    transition: color .3s, background-color .3s, border .3s;


    &:hover {
        color: #EAFD74;;
        background-color: black;
        border: 1px solid #EAFD74;;
    }
}
</style>