<template>
<div id="app">
  <navigation-bar />
  <router-view v-if="valid"/>
  <!-- TODO: Implement error/old timestamp message -->
  <cookie-consent />
</div>
</template>

<script>
import { RouterView, useRoute } from 'vue-router';
import NavigationBar from '@/components/NavigationBar.vue';
import CookieConsent from './components/CookieConsent.vue';
import { CookieHandler, CONSENT_TYPES } from '@/helpers/cookieHandler';

export default {
  name: 'App',
  components: {
    RouterView,
    NavigationBar,
    CookieConsent,
  },
  data() {
    return {
      route: null,
      valid: false,
    }
  },
  watch: {
    route: {
      handler(routeNew) {
        setTimeout(() => {
          // const isLive = window.location.href.includes('prod');
          // this.valid = routeNew.query && routeNew.query.stamp && this.isYoungerThanOneHour(routeNew.query.stamp) && isLive == false;
          this.valid = routeNew.query;
          
          const activeCategory = document.querySelector('.category-title.active');
          // relies on scroll-margin-top: 60px on category-title
          if (activeCategory && routeNew) activeCategory.scrollIntoView({ behavior: 'smooth' });
        }, 350);
      },
      deep: true,
    },
  },
  mounted() {
    this.route = useRoute();

    // resolve cookie status
    const consent = CookieHandler.get(CONSENT_TYPES.YOUTUBE);
    if (consent && consent['interacted'] && true === consent['accepted']) {
      this.$store.dispatch('setConsentAccepted', true);
    } else {
      this.$store.dispatch('setConsentAccepted', false);
    }
  },
  methods: {
    isYoungerThanOneHour(timestamp) {
      const TIME_TO_EXCEED = 3600000;
      const oneHourAgo = Date.now() - TIME_TO_EXCEED;

      return parseInt(timestamp) > oneHourAgo;
    }
  }
}
</script>

<style>
#app {

}

*, * *, h1, h2, h3, p, i, li, ul {
  margin: 0;
  padding: 0;

  font-family: Helvetica Neue, Arial, sans-serif;

  color: rgb(220, 220, 220);
}

img {
  object-fit: contain;
}

body {
  background-color: black;
  margin-bottom: 50px;
  width: 100vw;
  line-height: 1.3;
  height: 100%;
}

</style>
