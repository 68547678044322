import { createApp } from 'vue';
import App from './App.vue';

import router from './router';
import { store } from '@/store/index.js'

const app = createApp(App);

// router
app.use(router);
// stores
app.use(store);

app.mount('#app');
