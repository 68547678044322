<template>
    <button class="custom-button">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "CustomButton",
  };
</script>

<style lang="scss">
.custom-button {
    background-color: black;
    color: rgb(220, 220, 220);
    border: 1px solid rgb(220, 220, 220);
    padding: 5px 10px;
    margin-top: 10px;
    cursor: pointer;

    transition: background-color .3s, color .3s;

    &:hover {
        background-color: rgb(220, 220, 220);
        color: black;
    }
}
</style>