import { contentType } from "@/api/types";

export const IMG_SIZES = {
    SMALL: 'smallUrl',
    STANDARD: 'standardUrl',
    THUMBNAIL: 'thumbUrl',
};

export const BREAKPOINT = {
    MOBILE: 640,
    TABLET: 1280,
    DESKTOP: 1680,
    LARGE_DESKTOP: 1920,
}

export function createContentPageObject(responseData) {

    return {
        id: responseData.id.toString(),
        title: responseData.attributes.title,
        created: responseData.attributes.createdAt,
        edited: responseData.attributes.publishedAt,
        rank: responseData.attributes.rank,
        contentElements: createContentElements(responseData.attributes.content),
        category: createContentCategoryObject(responseData.attributes.content_category.data),
    };
}

// build usable content elements
export function createContentElements(contentElementsUnsorted) {
    return contentElementsUnsorted.map(contentElement => {

        if (contentElement[contentType.text]) {
            
            return {
                type: 'text',
                text: contentElement.textBox,
            }
        }
        
        if (contentElement[contentType.image]) {
            const currentElement = contentElement.image.data.attributes;
            return {
                id: contentElement.id,
                type: 'image',
                name: currentElement.name,
                caption: contentElement.caption,
                versions: {
                    thumbUrl: currentElement.formats ? currentElement.formats.thumbnail ? currentElement.formats.thumbnail.url : null : null,
                    smallUrl: currentElement.formats ? currentElement.formats.small ? currentElement.formats.small.url : null : null,
                    standardUrl: currentElement.url,
                }
            }
        }
        if (contentElement[contentType.video]) {
            
            return {
                type: 'video',
                caption: contentElement.caption,
                url: contentElement.video,
            }
        }
        if (contentElement[contentType.pdf]) {
            const currentElement = contentElement.pdf.data.attributes;

            return {
                type: 'pdf',
                name: currentElement.name,
                description: contentElement.label,
                url: currentElement.url,
            }
        }
    })
}

// make content category objects easier to use
export function createContentCategoryObject(contentCategoryResponse) {
    if (!contentCategoryResponse) {
        return null;
    }

    return {
        // TODO: keep id as number?
        id: contentCategoryResponse.id.toString(),
        // TODO: change type => identifier
        title: contentCategoryResponse.attributes.title,
        color: contentCategoryResponse.attributes.color,
        rank: contentCategoryResponse.attributes.rank,
    }
}  

// create displayable relation between categories and objects
// the category acts as a major headline and has its own content pages
export function createDisplayableCategoryToContentPageObjects(contentPages, categories) {
    const contentSortedForCategories = [];

    for(const category of categories) {
        contentSortedForCategories.push({
            id: category.id,
            title: category.title,
            rank: category.rank,
            // subordinate all belonging content pages to category
            
            // contentPages: contentPages.filter(contentPage => {
            //     return contentPage.category.title === category.title;
            // }),

            contentPages: extractSortedContentPagesForCategory(contentPages, category.title),
        });
    }

    return contentSortedForCategories;
}

function extractSortedContentPagesForCategory(contentPages, categoryTitle) {
    const contentPagesForCategory = contentPages.filter(contentPage => {
        if (contentPage.category) {
            return contentPage.category.title === categoryTitle;
        }
    });

    return contentPagesForCategory.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
}
